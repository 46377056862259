import React from "react";
import cn from "classnames";
import { BgGradient } from "../components/bg-gradient";

import { SEO } from "../components/seo";

import beisheimSrc from "../assets/partners/beisheimstiftung.png";
import mireviSrc from "../assets/partners/mirevi.png";
import hsdSrc from "../assets/partners/hsd.png";
import sspbSrc from "../assets/logo.svg";
import { MajorLink } from "../components/major-link";

const P: React.FC = ({ children }) => <p className="my-5">{children}</p>;
const A: React.FC<{ href: string }> = ({ children, href }) => (
  <a
    href={href}
    className="underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
const H2: React.FC = ({ children }) => (
  <h2 className="text-2xl mt-12 mb-4 font-bold">{children}</h2>
);
const H3: React.FC = ({ children }) => (
  <h3 className="text-xl mt-6 mb-2 font-semibold">{children}</h3>
);
const Ul: React.FC = ({ children }) => <ul>{children}</ul>;

function PartnerLogo({
  href,
  imgSrc,
  imgAlt,
  className,
}: {
  href: string;
  imgSrc: string;
  imgAlt: string;
  className?: string;
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn("block", className)}
    >
      <img src={imgSrc} alt={imgAlt} />
    </a>
  );
}

const Page = (): JSX.Element => (
  <>
    <SEO
      title="Mitwirkende"
      description="Die Namen der Beteiligten, die am Aufklappbuch mitgearbeitet haben."
    />
    <BgGradient />
    <div className="relative max-w-xl mx-auto px-6 my-20">
      <h1 className="text-4xl font-bold mb-8 pt-8">Mitwirkende</h1>
      <P>
        Ein Projekt in Zusammenarbeit zwischen{" "}
        <A href="https://www.schloss-benrath.de">
          Stiftung Schloss und Park Benrath
        </A>{" "}
        und der <A href="https://hs-duesseldorf.de">Hochschule Düsseldorf</A>{" "}
        durch das <A href="https://mirevi.de">MIREVI</A> Team.
      </P>
      <H2>Stiftung Schloss und Park Benrath</H2>
      <H3>Koordination</H3>
      <Ul>
        <li>Stefanie Kemper</li>
        <li>Jennifer Sawatzki</li>
        <li>Stefan Schweizer</li>
      </Ul>
      <H2>Hochschule Düsseldorf (MIREVI)</H2>
      <H3>Illustration und 3D-Modellierung</H3>
      <Ul>
        <li>Gabriel Abu Rabia</li>
      </Ul>
      <H3>Webdesign und Programmierung</H3>
      <Ul>
        <li>Paul Kretschel</li>
      </Ul>
      <H3>Konzeption und Design</H3>
      <Ul>
        <li>Patrick Kruse</li>
      </Ul>
      <H3>Koordination</H3>
      <Ul>
        <li>Ivana Druzetic-Vogel</li>
      </Ul>
      <H2>Gefördert durch</H2>
      <Ul>
        <li>Prof. Otto Beisheim Stiftung</li>
      </Ul>
      <H2>Links</H2>
      <div className="my-8">
        <PartnerLogo
          href="https://www.beisheim-stiftung.com/de/de"
          imgSrc={beisheimSrc}
          imgAlt="Beisheim Stiftung"
          className="w-52"
        />
      </div>
      <div className="my-8 flex items-center">
        <div className="mr-10">
          <PartnerLogo
            href="https://mirevi.de"
            imgSrc={mireviSrc}
            imgAlt="MIREVI"
            className="w-20"
          />
        </div>
        <div>
          <PartnerLogo
            href="https://hs-duesseldorf.de"
            imgSrc={hsdSrc}
            imgAlt="Hochschule Düsseldorf"
            className="w-24"
          />
        </div>
      </div>
      <div className="my-8">
        <PartnerLogo
          href="https://www.schloss-benrath.de"
          imgSrc={sspbSrc}
          imgAlt="Stiftung Schloss und Park Benrath"
          className="w-32"
        />
      </div>
      <div className="mt-16">
        <MajorLink to="/">Zurück zum Buch</MajorLink>
      </div>
    </div>
  </>
);

export default Page;
